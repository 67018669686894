import { useMutation } from "@apollo/client";
import Button from "components/Button";
import Input from "components/Input";
import moment from "moment";
import { useState } from "react";
import { CREATE_SUPPLIER_STOCK_UPDATE_REQUEST } from "./graphql";
import { toast } from "react-toastify";
import { parseError } from "Apollo";

const EditDeliveryView = ({ supplierId, productId, delivery, hide }) => {
  const [qty, setQty] = useState(delivery.qty);
  const [remark, setRemark] = useState("");

  const [submit, submitRes] = useMutation(CREATE_SUPPLIER_STOCK_UPDATE_REQUEST, {
    onCompleted() {
      toast.success("Request submitted successfully. We will review and update the inventory number accordingly. 请求已成功提交。我们将审核并更新库存数量。");
      hide();
    },
    onError(error) {
      toast.error(parseError(error));
    },
  });

  function submitHandler() {
    submit({
      variables: {
        supplierId,
        data: JSON.stringify({
          products: [{ supplierStockRecordId: delivery.id, id: productId, qty, readyDate: delivery.date, remark, updateType: "Update Existing Delivery" }],
        }),
      },
    });
  }

  return (
    <div>
      <div className="card px-6 py-3">
        <table>
          <tbody>
            <tr>
              <td>
                <label>Date 日期: </label>
              </td>
              <td className="w-full">{moment(delivery.date).format("YYYY-MM-DD")}</td>
            </tr>
            <tr>
              <td>
                <label>Quantity 数量: </label>
              </td>
              <td>
                <Input className="w-auto text-center" value={qty} onChange={(e) => setQty(e.target.value)} />
              </td>
            </tr>
            <tr>
              <td>
                <label htmlFor="">Remark 备注:</label>
              </td>
              <td>
                <Input className="w-full" value={remark} onChange={(e) => setRemark(e.target.value)} />
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <div className="mt-6">
        <div className="opacity-70 text-sm">
          Submit an updated qty for this delivery. We will review and handle the request. 请提交此交货的新数量, 我们将会审核并处理请求。
        </div>

        <div className="mt-6">
          <Button primary onClick={submitHandler} loading={submitRes.loading}>
            Submit 提交
          </Button>
        </div>
      </div>
    </div>
  );
};

export default EditDeliveryView;
