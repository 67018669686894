import { useLazyQuery, useQuery } from "@apollo/client";
import Page from "components/Page";
import { useParams } from "react-router-dom";
import { FETCH_PRODUCT_STOCK_DETAIL, SUPPLIER_FETCH_SO_RELATED_PURCHASE_ORDERS } from "./graphql";
import Spinner from "components/Spinner";
import Errors from "components/Errors";
import moment from "moment";
import { useContext, useEffect } from "react";
import { AppContext } from "AppContainer";
import Status from "components/Status";
import Button from "components/Button";
import { BsPencilFill } from "react-icons/bs";
import { useModals } from "components/Modal";
import EditDeliveryView from "./EditDeliveryView";

const InventoryProductPage = () => {
  const { id } = useParams();
  const { loading, error, data } = useQuery(FETCH_PRODUCT_STOCK_DETAIL, {
    variables: { id },
  });
  const modal = useModals();

  const { user } = useContext(AppContext);
  const [fetchPO, fetchPORes] = useLazyQuery(SUPPLIER_FETCH_SO_RELATED_PURCHASE_ORDERS);

  useEffect(() => {
    if (data) {
      const vendorId = user.supplier.odooId;
      const productOdooId = data.product.odooId;
      const soNumbers = data.product.supplierStocks
        .flatMap((i) => i.usages.filter((u) => u.plan.odooObject.objectType === "ORDER"))
        .map((i) => i.plan.odooObject.objectName);
      if (soNumbers.length > 0) fetchPO({ variables: { vendorId, productOdooId, soNumbers } });
    }
  }, [data, user.supplier.odooId, fetchPO]);

  if (loading) return <Spinner />;
  if (error) return <Errors errors={error} />;

  const stocks = data.product.supplierStocks;

  function editDelivery(delivery) {
    modal.present({
      title: "Edit Delivery / 修改交货信息",
      subtitle: `[${data.product.number}] ${data.product.name}`,
      center: true,
      maxWidth: "max-w-3xl",
      children: <EditDeliveryView supplierId={user.supplier.id} productId={id} delivery={delivery} hide={modal.dismiss} />,
    });
  }

  let rows = [];

  stocks.forEach((stock) => {
    rows.push({
      id: stock.id,
      type: "STOCK",
      date: stock.readyDate,
      name: "Supplier Stock Delivery",
      qty: stock.qty,
      forecast: stock.latestQty,
      remark: stock.remark,
    });

    stock.usages.forEach((usage) => {
      rows.push({
        type: "USAGE",
        date: usage.plan.readyDate,
        name: usage.plan.name + " - " + usage.plan.odooObject?.objectName,
        po: fetchPORes.data ? fetchPORes.data.soRelatedPos.find((i) => i.so === usage.plan.odooObject?.objectName)?.pos : "",
        qty: -usage.qty,
        state: usage.plan.state,
      });
    });
  });

  rows = rows.sort((a, b) => new Date(a.date) - new Date(b.date));

  for (let i = 0; i < rows.length; i++) {
    // add forecast value to each row.
    if (i === 0) {
      rows[i].forecast = rows[i].qty;
    } else {
      rows[i].forecast = rows[i - 1].forecast + rows[i].qty;
    }
  }
  // Now sort rows by date

  console.log("fetchPORes", fetchPORes);

  const lastForecast = rows[rows.length - 1].forecast;

  return (
    <Page title={data.product.number} subtitle={data.product.name} backTo="./..">
      <div>
        <div className="my-2 whitespace-nowrap text-sm">
          <table>
            <thead>
              <tr>
                <th className="px-8">Date / 日期</th>
                <th className="px-8">Reference</th>
                <th className="px-8">
                  <div className="flex items-center space-x-2">
                    <span>PO / 采购单</span>

                    <div className="relative">
                      <Spinner size={14} className={fetchPORes.loading ? "opacity-100" : "opacity-0"} />
                    </div>
                  </div>
                </th>
                <th className="px-8 text-right">QTY / 数量</th>
                <th className="px-8 text-right">Forecast / 预计剩余数量</th>
              </tr>
            </thead>
            <tbody>
              {rows.map((row, index) => (
                <tr
                  key={index}
                  className={`border-y
                ${row.type === "STOCK" ? "bg-blue-100 font-semibold border-blue-200" : ""}
                `}
                >
                  <td className="px-8">{moment(row.date).format("YYYY-MM-DD")}</td>
                  <td className="px-8 w-full">
                    <div className="flex items-center space-x-2">
                      <div>
                        <div className="flex items-center">
                          <span>{row.name}</span>
                          {row.type === "STOCK" && (
                            <Button className="ml-2 text-xs" icon={<BsPencilFill />} onClick={() => editDelivery(row)}>
                              修改
                            </Button>
                          )}
                        </div>
                        {row.remark && <div className=" font-normal text-xs">{row.remark}</div>}
                      </div>
                      {row.type === "USAGE" && <Status status={row.state} />}
                    </div>
                  </td>
                  <td className="px-8 w-full">{row.po}</td>
                  <td className="px-8 text-right">{row.qty}</td>
                  <td className="px-8 text-right">{row.forecast}</td>
                </tr>
              ))}
            </tbody>
            <tfoot>
              <tr>
                <td colSpan="4"></td>
                <td className="px-8 text-right font-bold text-xl">{lastForecast}</td>
              </tr>
            </tfoot>
          </table>
        </div>
      </div>
    </Page>
  );
};

export default InventoryProductPage;
