import { useQuery } from "@apollo/client";
import Page from "components/Page";
import { FETCH_SUPPLIER_STOCK_PRODUCTS } from "./graphql";
import Spinner from "components/Spinner";
import Errors from "components/Errors";
import { parseError } from "Apollo";
import { Link } from "react-router-dom";

const InventoryList = () => {
  const { loading, error, data } = useQuery(FETCH_SUPPLIER_STOCK_PRODUCTS);

  if (loading) return <Spinner />;
  if (error) return <Errors errors={parseError(error)} />;

  const products = data.supplierStockProducts;
  const productsHasNoStock = products.filter((i) => i.supplierStockForecast === 0);

  return (
    <Page title="Inventory" topbarRightView={<Link to="submit-delivery">Submit Delivery</Link>}>
      <div className="mx-10 my-6 text-sm opacity-70">
        This page shows the inventory number Waboba owned in our supplier.
        <br />
        Waboba will manage the inventory numbers during our operation. You can also submit an inventory update anytime if you want then we will review and
        update the inventory number accordingly.
        <br />
        本页面显示Waboba在我们供应商处拥有的库存数量。Waboba将在我们的运营期间管理库存数量。您也可以随时提交库存更新，我们将审核并相应更新库存数量。
      </div>

      <div className="card m-8 mt-0 flex-1 flex flex-col overflow-auto p-0 rounded-xl">
        <div className="overflow-auto relative text-sm">
          <table>
            <thead>
              <tr>
                <th className="px-6 py-3">Product / 产品</th>
                <th className="px-6 py-3 text-right">Planning QTY / 计划使用</th>
                <th className="px-6 py-3 text-right">Forecast QTY / 预计剩余数量</th>
              </tr>
            </thead>
            <tbody>
              {products.map((product) => (
                <tr
                  key={product.id}
                  className={`
                    hover:bg-blue-50 dark:hover:bg-gray-800 border-b dark:border-gray-700
                    ${
                      product.latestSupplierStock?.latestQty === 0
                        ? "text-red-600 bg-red-50 dark:text-red-500 dark:bg-red-900 dark:hover:bg-red-900 dark:bg-opacity-20 dark:hover:bg-opacity-50 border-red-100"
                        : "border-gray-100 dark:border-gray-100"
                    }
                  `}
                >
                  <td className="px-6">
                    <Link to={product.id}>
                      [{product.number}] {product.name}
                    </Link>
                  </td>
                  <td className="px-6 text-right text-gray-800 dark:text-gray-100">{product.supplierStockPlanningQty}</td>
                  <td className="px-6 text-right text-gray-800 dark:text-gray-100">{product.supplierStockForecast}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        <div className="italic opacity-70 text-sm px-6 py-3 border-t border-gray-100 dark:border-gray-700">
          <span>Total {products.length} products. </span>
          {productsHasNoStock.length > 0 ? <span className="text-red-600">{productsHasNoStock.length} products is running out of stock.</span> : null}
        </div>
      </div>
    </Page>
  );
};

export default InventoryList;
