import { gql } from "@apollo/client";

export const FETCH_SUPPLIER_STOCK_PRODUCTS = gql`
  query FETCH_SUPPLIER_STOCK_PRODUCTS {
    supplierStockProducts {
      id
      odooId
      number
      name
      supplierStockForecast
      supplierStockPlanningQty
    }
  }
`;

export const FETCH_PRODUCT_STOCK_DETAIL = gql`
  query FETCH_PRODUCT_STOCK_DETAIL($id: ID!) {
    product(id: $id) {
      id
      odooId
      number
      name
      supplierStockForecast
      supplierStocks {
        id
        qty
        readyDate
        usages {
          id
          plan {
            name
            wisCustomer {
              name
            }
            odooObject {
              objectName
              objectType
            }
            state
          }
          qty
        }
        remark
      }
    }
  }
`;

// Deprecated
export const SUPPLIER_FETCH_INVENTORY = gql`
  query SUPPLIER_FETCH_INVENTORY($supplierId: Int!) {
    products(hasSupplierStocks: true, supplierId: $supplierId) {
      total
      results {
        id
        odooId
        name
        number
        latestSupplierStock {
          id
          active
          supplier {
            id
            name
            odooId
          }
          qty
          latestQty
          planingPlanConsumedTotal
          readyDate
          updatedAt
          remark
          usages {
            id
            plan {
              name
              wisCustomer {
                name
              }
              odooObject {
                objectName
                objectType
              }
              state
            }
            qty
          }
        }
      }
    }
  }
`;

export const CREATE_SUPPLIER_STOCK_UPDATE_REQUEST = gql`
  mutation CREATE_SUPPLIER_STOCK_UPDATE_REQUEST($supplierId: ID!, $data: String!) {
    createSupplierStockUpdateRequest(supplierId: $supplierId, data: $data) {
      supplierStockUpdateRequest {
        id
        state
      }
    }
  }
`;

export const SUPPLIER_FETCH_SO_RELATED_PURCHASE_ORDERS = gql`
  query SUPPLIER_FETCH_SO_RELATED_PURCHASE_ORDERS($vendorId: Int!, $productOdooId: Int!, $soNumbers: [String]) {
    soRelatedPos(vendorId: $vendorId, productOdooId: $productOdooId, soNumbers: $soNumbers) {
      so
      pos
    }
  }
`;
