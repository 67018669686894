import { useQuery } from "@apollo/client";
import { FETCH_SUPPLIER_STOCK_PRODUCTS } from "./graphql";
import Spinner from "components/Spinner";
import Errors from "components/Errors";
import { parseError } from "Apollo";
import SearchBar from "components/SearchBar";

const ProductSelector = ({ onSelect }) => {
  const { loading, error, data } = useQuery(FETCH_SUPPLIER_STOCK_PRODUCTS);

  if (loading) return <Spinner />;
  if (error) return <Errors errors={parseError(error)} />;

  const products = data.supplierStockProducts;

  return (
    <div>
      {products.length > 10 && (
        <div>
          <SearchBar />
        </div>
      )}

      <table>
        <tbody>
          {products.map((product) => (
            <tr className="border-y cursor-pointer hover:text-blue-600" key={product.id} onClick={() => onSelect(product)}>
              <td>
                [{product.number}] {product.name}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default ProductSelector;
