import { Route, Routes } from "react-router-dom";
import InventoryList from "./InventoryList";
import RequestUpdate from "./RequestUpdate";
import InventoryProductPage from "./InventoryProductPage";
import SubmitDeliveryPage from "./SubmitDeliveryPage";

const Inventory = () => {
  return (
    <Routes>
      <Route path="/" element={<InventoryList />} />
      <Route path="/:id" element={<InventoryProductPage />} />
      <Route path="/request-update" element={<RequestUpdate />} />
      <Route path="/submit-delivery" element={<SubmitDeliveryPage />} />
    </Routes>
  );
};

export default Inventory;
